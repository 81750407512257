<template>
  <div class="wrap">
    <div class="The_header">
      <ul>
        <li class="left">
          <router-link to="/team">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>Level {{ level }}</li>
      </ul>
    </div>
    <div class="Account_fixed">
      <AInput
        v-model:value="userAccount"
        :placeholder="t('common.placeholder.searchPhone')"
        class="searchInput"
      >
      </AInput>
      <a-button type="primary" class="button1" @click="handleSearch">{{t("common.button.search")}}</a-button>
    </div>
    <BetterScroll
      ref="scrollRef"
      @pullingUp="fetchMorePage"
      class="BetterScroll"
      v-if="alreadyReq"
    >
      <div class="main main_page">
        <div class="team_details">
          <div class="team_details_list">
            <template v-if="listItems && listItems.length > 0">
              <div v-for="(item, index) in listItems" :key="index" class="bata">
                <h1>
                  <StaticImage
                    :url="'Head/' + item.avatarAddress + '.png'"
                  ></StaticImage>
                </h1>
                <h2>
                  <span>{{ item.userNick }}</span>
                  <VipLevel :level="+level" />
                </h2>
                <h2 v-if="item.userBalance != undefined">
                  {{ t("team.label.balance") }}:
                  <span>{{ formatMoney(item.userBalance) }}</span>
                </h2>
                <h2  @click.prevent="doCopy(item.userAccount)">
                  {{ t("team.label.cellNo") }}:
                  <span class="cellNo">{{ item.userAccount }}</span>
                </h2>
                <h3>
                  {{ t("team.label.registrationDate") }}:
                  <b>{{ formatServerDateTime(item.createDate) }}</b>
                </h3>
                <ul>
                  <li>
                    <b>{{ formatMoney(item.commissionTotal) }}</b>
                    {{ t("team.label.commission") }}
                  </li>
                  <li>
                    <b>{{ formatMoney(item.chargeTotalAmount) }}</b>
                    {{ t("team.label.totalRecharge") }}
                  </li>
                  <li>
                    <b>{{ formatMoney(item.payTotalAmount) }}</b>
                    {{ t("team.label.totalWithdraw") }}
                  </li>
                </ul>
              </div>
            </template>
            <div v-else class="no_data">
              <p>{{ t("common.message.noRecords") }}</p>
            </div>
          </div>
        </div>
      </div>
    </BetterScroll>
  </div>
</template>
<script>
import { ref, unref, watch, computed, reactive, onMounted } from "vue";
import BetterScroll from "@/components/BetterScroll.vue";
import { copyText } from "vue3-clipboard";
import { message } from "ant-design-vue";
import { formatMoney,formatServerDateTime } from "@/utils/formatter";
import router from "@/router";
import VipLevel from "@/components/VipLevel.vue";
import StaticImage from "@/components/StaticImage.vue";
import { formatMobile } from "@/utils/formatter";
import { useI18n } from "@/lang";
import api from "@/api";
export default {
  components: { BetterScroll, VipLevel, StaticImage },
  setup() {
    const { t } = useI18n();
    const userAccount = ref('')
    const userAccount_toSend = ref('')
    const level = computed(() => {
      const {
        params: { level },
      } = unref(router.currentRoute);
      return +level;
    });

    const scrollRef = ref(null);
    const isStopReq = ref(false);
    const currentPage = ref(0);
    const alreadyReq = ref(false);
    const listItems = reactive([]);
    const handleSearch = ()=>{
      userAccount_toSend.value = userAccount.value
      currentPage.value = 0
      isStopReq.value = false
      alreadyReq.value = false
      listItems.splice(0,listItems.length)
      fetchMorePage()
    }
    const fetchMorePage = () => {
      if (isStopReq.value) {
        return;
      }
      let formTosend = {
        current: currentPage.value + 1,
        size: 10,
        levelName: "VIP" + router?.currentRoute?.value?.params?.level,
      };
      if (userAccount_toSend.value) {
        formTosend.userAccount = userAccount_toSend.value;
      }
      api.teamMember(formTosend).then((res) => {
        if (res?.data?.success) {
          alreadyReq.value = true;
          if (+res.data.data?.pages <= +currentPage.value + 1) {
            isStopReq.value = true;
          }
          currentPage.value += 1;
          listItems.push(...res.data.data?.records);
        }
      });
    };

    onMounted(() => {
      fetchMorePage();
    });

    watch(listItems, () => {
      if (scrollRef.value) {
        scrollRef.value.refresh();
      }
    });

    function doCopy(text) {
      copyText(text, undefined, (error, event) => {
        console.log(event);
        if (!error) {
          message.success({
            key: "copy",
            content: t("common.clipboard.success"),
          });
        }
      });
    }

    return {
      doCopy,
      scrollRef,
      listItems,
      fetchMorePage,
      formatMoney,
      level,
      formatMobile,
      t,
      alreadyReq,
      userAccount,
      handleSearch,
      formatServerDateTime
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap .main {
  position: relative;
  top: 0;
}
.button1{
  height: 40px;
  width:75px;
  border:none;
}
.searchInput{
  width:300px;
  border: none;
}
.cellNo{
  text-decoration: underline;
}
</style>
